<!-- Localized -->
<template>
  <div>
    <h2>{{ $t('components.saas.adverse_media.associated_risk.associated_risk') }}</h2>
    <div class="my-4" id="status">
      <label class="label mb-3">{{ $t('components.saas.adverse_media.associated_risk.rag_status') }}</label>
      <div class="flex space-x-6">
        <label class="radio-label flex items-center">
          <input
            name="stats_color"
            type="radio"
            value="Red"
            v-model="selectedColor"
            :disabled="!hasRiskCategory"
            class="radio mb-0.5"
          />
          {{ $t('components.saas.adverse_media.associated_risk.red') }}
        </label>
        <label class="radio-label flex items-center">
          <input
            name="stats_color"
            type="radio"
            value="Amber"
            v-model="selectedColor"
            :disabled="!hasRiskCategory"
            class="radio mb-0.5"
          />
          {{ $t('components.saas.adverse_media.associated_risk.amber') }}
        </label>
      </div>
    </div>
    <div class="flex flex-wrap ">
      <div
        v-for="(item, indx) in this.individualData.risk_categories"
        :key="indx"
        class="label mx-2  mb-4"
        :class="checkCategories(item)"
      >
        <span>{{ item }}</span>
        <span class="" @click="deleteRisk(item)">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 384 512"
          >
            <path
              d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"
            />
          </svg>
        </span>
      </div>
    </div>
    <div class="my-3">
      <select
        class="select select-bordered select-md text-gray-400"
        :placeholder="$t('components.saas.adverse_media.associated_risk.select_categories')"
        v-model="getValue"
        style="min-width: 65vw"
        @click="handleSelect(getValue)"
      >
        <option disabled :value="null" hidden>{{ $t('components.saas.adverse_media.associated_risk.select_categories') }}</option>
        <optgroup :label="$t('components.saas.adverse_media.associated_risk.risk_categories')">
          <option
            class="text-black"
            v-for="(option, idx) in this.getOptions"
            :key="idx"
            v-show="option.category_type === 'Risk Categories'"
            :value="option.category"
          >
            {{ option.category }}
          </option>
        </optgroup>
        <optgroup :label="$t('components.saas.adverse_media.associated_risk.nonrisk_categories')">
          <option
            class="text-black"
            v-for="(option, idx) in this.getOptions"
            v-show="option.category_type !== 'Risk Categories'"
            :key="idx"
            :value="option.category"
          >
            {{ option.category }}
          </option>
        </optgroup>
      </select>
      <!-- <dropdown reposition searchable class="h-10" :placeholder="$t('components.saas.adverse_media.associated_risk.groups')" :options="this.allOptions()"
                v-model="globalGroups" :multiple="true" :taggable="false"
                :colors="{ text: '#282828', bg: 'white', svg: '#282828', border: '#e8e8e8', }" :limit="1" units="Groups"
                width="90%" minWidth="90%" :config="{ label: 'name', trackBy: 'id' }">
            </dropdown> -->
    </div>
    <!-- <div class="">
            <div class="flex items-center">
                <h2 class="text-black mr-4">{{ $t('components.saas.adverse_media.associated_risk.adverse_content') }}</h2>
                <label for="my-modal-2" class="rounded-full border-2 p-2 modal-button" style="border-color: #910000;">
                    <Add />
                </label>
                <input type="checkbox" id="my-modal-2" class="modal-toggle">
                <div class="modal shadow">
                    <div class="modal-box">
                        <div class="flex justify-end mb-2" for="my-modal-2">
                            <label for="my-modal-2" class="">
                                <Cross />
                            </label>
                        </div>
                        <div class="p-4">
                            <div>
                                <label class="label mb-1">{{ $t('components.saas.adverse_media.associated_risk.upload_image') }}</label>
                                <FormulateInput ref="file" type="file" v-model="files"
                                    class="w-full rounded-xl group cursor-pointer" @input="handleFiles" multiple
                                    :add-label="true" :disabled="disabled" />
                            </div>
                            <div class="my-6">
                                <label class="label mb-1">{{ $t('components.saas.adverse_media.associated_risk.image_caption') }}</label>
                                <FormulateInput type="text" v-model="files" class="w-full rounded-xl group cursor-pointer"
                                    :add-label="true" :disabled="disabled" />
                            </div>
                            <div class="my-6">
                                <label class="label mb-1">{{ $t('components.saas.adverse_media.associated_risk.posted_on') }}</label>
                                <input type="date" :placeholder="$t('components.saas.adverse_media.associated_risk.posted_on')" class="input input-xs input-bordered">
                            </div>
                            <div>
                                <label class="label mb-1">{{ $t('components.saas.adverse_media.associated_risk.posted_on') }}</label>
                                <select class=" select select-bordered select-sm w-full  text-grey-400">
                                    <option disabled="disabled" selected="selected" class="text-grey">{{ $t('components.saas.adverse_media.associated_risk.select_categories') }}</option>
                                    <option>{{ $t('components.saas.adverse_media.associated_risk.telekinesis') }}</option>
                                    <option>{{ $t('components.saas.adverse_media.associated_risk.time_travel') }}</option>
                                    <option>{{ $t('components.saas.adverse_media.associated_risk.invisibility') }}</option>
                                </select>
                            </div>
                            <div class="modal-action">
                                <label for="my-modal-2" class="w-full button btn-outline">{{ $t('actions.accept') }}</label>
                                <label for="my-modal-2" class="w-full button btn-red">{{ $t('actions.close') }}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
  </div>
</template>
<script>
// import Add from '../../assets/svg/add.svg';
// import Cross from '../../assets/svg/cross-red.svg';
// import dropdown from '@/components/dropdown-base';
import axios from "@/axios";
import { mapGetters } from "vuex";

export default {
  name: "AssociatedRisk",
  components: {
    // Add,
    // Cross,
    // dropdown,
  },
  props: {
    mainEntity: Array,
    individualData: Object,
  },
  data() {
    return {
      age: null,
      files: [],
      colorStatus: "",
      getOptions: [],
      getValue: null,
      defaultSelectedColor: "Red",
    };
  },
  methods: {
    handleSelect(getValue) {
      if (typeof getValue == "string") {
        let set = new Set(this.individualData.risk_categories);
        set.add(getValue);
        this.individualData.risk_categories = [...set];
        this.getValue = null;
      }
    },
    deleteRisk(itemToRemove) {
      this.individualData.risk_categories = this.individualData.risk_categories.filter(
        (item) => item !== itemToRemove
      );
      this.$forceUpdate();
    },
    checkCategories(item) {
      let risk = this.getOptions.find((v) => v.category == item);
      if (risk) {
        return risk.category_type === "Risk Categories"
          ? this.individualData.entity_status === "Amber"
            ? "amberLabel"
            : "redLabel"
          : "greenLabel";
      }
    },
    async getCategoriesOption() {
      try {
        let headers = { "x-case-id": this.getNeoCaseId };
        const url = "/service/risk-categories";
        let categories = await axios.get(url, { headers });
        this.getOptions = categories.data.risk_data;
      } catch (err) {
        console.log(err);
      }
    },
  },
  async mounted() {
    await this.getCategoriesOption();
  },
  computed: {
    ...mapGetters(["getNeoCaseId"]),

    currentCheckName() {
      return this.$route.query?.check_name;
    },
    selectedColor: {
      get: function () {
        return this.individualData.risk_categories?.length ? this.individualData.entity_status : ''
      },
      set: function (newValue) {
        this.individualData.entity_status = newValue;
      }
    },
    hasRiskCategory() {
      return this.getOptions.find(x => ((this.individualData.risk_categories || []).includes(x.category) && x.category_type == "Risk Categories"));
    },
  },
  watch: {
    'individualData.risk_categories': {
      handler() {
        this.individualData.entity_status = this.hasRiskCategory && !this.selectedColor ? this.defaultSelectedColor : this.hasRiskCategory ? this.selectedColor : '';
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@shared/assets/mixins/mixins.scss";
@import "@shared/light-ray/assets/scss/color.scss";

.label {
  color: var(--blue-shade, #1a2036);
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
}

select {
  color: #9e9e9e;
}

option:not(:first-of-type) {
  color: black;
}

.button_custom {
  border-color: $lightray_product_color;
  border-radius: 6px;
  color: white;
  background-color: $lightray_product_color;
}

.redLabel {
  display: flex;
  padding: 7px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--light-red, #ffebeb);
  color: var(--red, #eb3131);
}

.amberLabel {
  display: flex;
  padding: 7px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background-color: rgba(235, 155, 0, 0.2);
  color: rgb(235, 155, 0);
}

.greenLabel {
  display: flex;
  padding: 7px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: rgba(5, 159, 39, 0.08);
  color: var(--green, #42b85c);
}

input[type="radio"] {
  height: 15px;
  appearance: auto;
}

.input {
  height: 2.5rem !important;
  border-radius: 8px !important;
  border: 1px solid var(--grey, #aeb1ba) !important;
  background: var(--white, #fff) !important;
  width: 100%;
}

.button {
  height: 34px;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.26px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-outline {
  border-radius: 8px;
  border: 1px solid $lightray_product_color;
  background: var(--white, #fff);
  color: $lightray_product_color;
}

.btn-red {
  border-radius: 8px;
  border: 0.5px solid $lightray_product_color;
  background: $lightray_product_color;
  color: var(--white, #fff);
}
</style>
