<!-- Localized -->
<template>
  <div>
    <offCanvas position="right">
      <nav
        class="h-16 bg-white border-b-2 p-2 flex justify-between  z-20"
      >
        <div class="w-80 flex items-center">
          <div class="w-10 mr-2" v-if="currentCheckName !== 'search-engine-tool'">
            <div
              v-if="individualData?.entity_identifier == 'name'"
              class="mx-2 w-9 h-9 rounded-full bg-red-200 flex justify-center items-center"
            >
              <User fill="#910000" />
            </div>
            <div
              v-else
              class="mx-2 w-9 h-9 rounded-full bg-red-200 flex justify-center items-center"
            >
              <Company fill="#910000" />
            </div>
          </div>
          <div
            v-show="!editName"
            class="flex justify-between items-center w-full"
            :title="currentCheckName === 'search-engine-tool' ? individualData.title : individualData.entity"
          >
            <div
              v-if="
                individualData?.source_name == 'judyrecords' &&
                  !individualData?.entity_meta_data.is_details_scraped
              "
            >
              {{ individualData?.entity_meta_data.jurisdiction }}
            </div>
            <div v-else-if="currentCheckName === 'search-engine-tool'"> 
              {{
                individualData?.title?.length > 40
                  ? `${individualData?.title?.substr(0, 40)}...`
                  : individualData?.title
              }}
            </div>
            <div v-else>
              {{
                individualData?.entity?.length > 40
                  ? `${individualData?.entity?.substr(0, 40)}...`
                  : individualData?.entity
              }}
            </div>
            <!-- <div @click="openEditName"> <Edit/></div> -->
          </div>
          <div
            v-show="editName"
            class="border p-2 rounded-md flex justify-between"
          >
            <input
              :placeholder="$t('actions.enter')"
              type="text"
              :value="individualData?.name"
              id="incorporation_date"
            />
            <button class="text-red-600">{{ $t('actions.update') }}</button>
          </div>
        </div>
        <div class="flex justify-between items-center">
          <div class="mr-8">
            <Button
              type="primary"
              class="button_outline px-6 py-2"
              :disabled="loadingBtn"
              @click="updateEntity"
              :text="$t('actions.save')"
              :loader="loadingBtn"
            ></Button>
            <!-- <button class="button_outline px-6 py-2" @click="updateEntity">{{ $t('actions.save') }}</button> -->
          </div>
          <div class="flex justify-center items-center">
            <!-- <div :class="individualData?.status?.toLowerCase() || 'unknown'"
                            class="mx-2 cursor-pointer w-7 h-7 rounded-full flex justify-center items-center">
                            <MinusIcon
                                v-if="individualData?.status?.toLowerCase() == 'unknown' || !individualData.status"
                                class="cursor-pointer" />
                            <Check v-else-if="individualData?.status?.toLowerCase() == 'confirmed'"
                                class="cursor-pointer" />
                            <Question v-else-if="individualData?.status?.toLowerCase() == 'potential'"
                                class="cursor-pointer" />
                            <CrossRed v-else-if="individualData?.status?.toLowerCase() == 'irrelvant'"
                                class="cursor-pointer" />
                        </div> -->
            <div class="mx-1 label-box dropdown_parent" :class="status">
              <button
                class="label"
                @click="toogleDropdown($event)"
                @blur.stop="closeDropdown"
                tabindex="0"
                :disabled="!checkPermission('checkOutput.edit_card')"
              >
                <Check
                  v-if="status == 'confirmed'"
                  class="cursor-pointer"
                />
                <Question
                  v-else-if="status == 'potential'"
                  class="cursor-pointer"
                />
                <CrossRed
                  v-else-if="status == 'irrelevant'"
                  class="cursor-pointer"
                />
                <MinusIcon v-else class="cursor-pointer" />
              </button>
              <ul
                v-if="showList"
                class="dropdown_content w-36 text-sm font-small bg-white shadow h-auto py-2"
              >
                <li
                  @mousedown="selectDropdown"
                  name="confirmed"
                  class="p-2 flex items-center justify-between border-b-2 border-gray-300 cursor-pointer"
                >
                  <span name="confirmed" class="" style="color: #67b52b"
                    >{{ $t('components.saas.confirmed') }}</span
                  >
                  <span
                    name="confirmed"
                    class="badge"
                    style="background: #f0f8ea"
                  >
                    <Check name="confirmed" />
                  </span>
                </li>
                <li
                  @mousedown.stop="selectDropdown"
                  name="potential"
                  class="p-2 flex items-center justify-between border-b-2 border-gray-300 cursor-pointer"
                >
                  <span name="potential" class="" style="color: #fc6713"
                    >{{ $t('components.saas.potential') }}</span
                  >
                  <span
                    name="potential"
                    class="badge"
                    style="background: rgba(252, 103, 19, 0.11)"
                  >
                    <Question name="potential" />
                  </span>
                </li>
                <li
                  @mousedown.stop="selectDropdown"
                  name="irrelevant"
                  class="p-2 flex items-center justify-between border-b-2 border-gray-300 cursor-pointer"
                >
                  <span name="irrelevant" class="" style="color: #eb3131"
                    >{{ $t('components.saas.false_matches') }}</span
                  >
                  <span
                    name="irrelevant"
                    class="badge"
                    style="background: #fbeded"
                  >
                    <CrossRed name="irrelevant" />
                  </span>
                </li>
                <li
                  @mousedown.stop="selectDropdown"
                  name="unknown"
                  class="p-2 flex items-center justify-between"
                >
                  <span name="unknown" class="" style="color: #8d8d8d"
                    >{{ $t('components.saas.unresolved') }}</span
                  >
                  <span
                    name="unknown"
                    class="badge"
                    style="background: #d7d7d7"
                  >
                    <MinusIcon name="unknown" />
                  </span>
                </li>
              </ul>
            </div>
            <div class="">
              <!-- <Threedot /> -->
            </div>
            <div class="mx-2 cursor-pointer" @click.stop="closeMenu">
              <Cross />
            </div>
          </div>
        </div>
      </nav>
      <div class="flex">
        <aside
          class="w-52 h-screen cursor-pointer text-base-content border-r-2"
        >
          <ul>
            <li
              v-for="item in sidebarItems"
              :key="item.id"
              class="pl-3 border-b-2 h-10 hover:bg-red-100"
              @click.stop="selectPage(item)"
              :class="{ 'active_card ': isActive(item) }"
            >
              <div
                class="flex items-center"
                :class="{ 'border-r-4 border-red-700': isActive(item) }"
              >
                <span
                  class="client-card-info self-stretch w-56 text-sm text-black-600 font-small capitalize px-2 py-2"
                >
                  {{ item.name }}
                </span>
              </div>
            </li>
          </ul>
        </aside>
        <div class="p-5 w-full overflow-x-scroll" style="height: 90vh">
          <!-- <div v-if="selectedPage === 1">
            <Attribute
              :individualData="getIndividualData"
              :data="attributeData"
            />
          </div> -->
          <div v-if="selectedPage === 2">
            <Relationship
              :individualData="individualData"
              @setIndividualData="setIndividualData"
              :mainEntity="mainEntity"
              :sourceName="sourceName"
              :mode="mode"
              :queryId="queryId"
            />
          </div>
          <div v-if="selectedPage === 3">
            <Notes :individualData="getIndividualData" />
          </div>
          <div v-if="selectedPage === 4">
            <AssociatedRisk :individualData="getIndividualData" />
          </div>
          <div v-if="selectedPage === 5">
            <Attachments :individualData="getIndividualData" />
          </div>
          <div v-if="isOutputShown"> 
            <OutputSectionFields 
              @setOutputFields="setOutputFields"
              :fields="fieldsData" 
              :sectionId="selectedPage"
              :individualData="individualData"
            />
          </div>
          <!-- <div v-if="selectedPage === 6">
                        <Monitoring :individualData="getIndividualData" />
                    </div> -->
        </div>
      </div>
    </offCanvas>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import Button from "@shared/components/button";
import offCanvas from "@shared/light-ray/common/off-canvas";
import User from "@shared/light-ray/assets/svg/user.svg";
import Company from "@shared/light-ray/assets/svg/company.svg";
import Cross from "@shared/light-ray/assets/svg/cross.svg";
import MinusIcon from "@shared/assets/minus-icon.svg";
import Check from "@shared/light-ray/assets/svg/check.svg";
import Question from "@shared/light-ray/assets/svg/question.svg";
// import Attribute from "../Attributes";
import Relationship from "../Relationships";
import Notes from "../Notes";
import AssociatedRisk from "../Associated-Risk";
import Attachments from "../Attachments";
// import { updateInternetDataDetails, updateNodeAttribute } from "../../services";
import CrossRed from "@shared/light-ray/assets/svg/cross-red.svg";
// import { areDeeplyEqual } from "@shared/light-ray/utils";
// import { EventBus } from "@/main.js";
import axios from "@/axios";
import {mapGetters} from "vuex";
import OutputSectionFields from "../output-section-fields"
import { checkPermission } from "@shared/utils/functions";


export default {
  name: "CanvasMenu",
  components: {
    OutputSectionFields,
    CrossRed,
    Question,
    Check,
    MinusIcon,
    // PencilBold,
    offCanvas,
    Button,
    User,
    Company,
    Cross,
    Relationship,
    Notes,
    AssociatedRisk,
    Attachments,
    // Monitoring,
  },
  props: {
    mode: {
      type: String,
      default: 'default',
    },
    isMainenity: Boolean,
    mainEntity: Array,
    individualData: Object,
    entityHead: Boolean,
    sourceName: String,
    confidence: Boolean,
    isShow: Boolean,
    graph: Boolean,
    disabled: Boolean,
    isEditCanvas: Boolean,
    editAble: {
      type: Boolean,
      default: false,
    },
    autorun: {
      type: Object,
      default: null,
    },
    checkId: {
      type: String,
      default: "",
    },
    queryId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      originalData: {},
      editName: false,
      showMenu: true,
      selectedPage: 2,
      sidebarData: [
        // {
        //     id: 1,
        //     name: "Attributes",
        // },
        {
          id: 2,
          key: 'relationships',
        },
        {
          id: 3,
          key: 'notes',
        },
        {
          id: 4,
          key: 'associated_risks',
          show: checkPermission('checkOutput.risk_tags'),
        },
        {
          id: 5,
          key: 'attachment',
        },
        // {
        //     id: 6,
        //     name: "Monitoring",
        // },
      ],
      attributeData: {
        companyNumber: "",
        incorporation_date: "",
        dissolution_date: "",
        country: "",
        state: "",
        firstName: "",
        middleName: "",
        lastName: "",
        dob: "",
        gender: "",
        mainEntity: false,
        showGraph: true,
        graphLabel: "",
      },
      loadingBtn: false,
      status: "unknown",
      showList: false,
      fieldsData: []
    };
  },
  created() {

    if (this.individualData?.status != null) {
      this.status = this.individualData.status.toLowerCase() || "unknown";
    }
  },
  methods: {
    checkPermission,
    ...mapMutations(["SET_NEED_GRAPH_UPDATE"]),
    setOutputFields(data) {
      this.fieldsData = data
    },
    async getOutputSectionData() {
      let payload = {
        tenant_id: this.getTenantId,
        client_id: this.$route?.query?.clientId || this.$route?.query?.client,
        check_id: this.$route?.query?.check || this.checkId,
        neo_case_id: this.getNeoCaseId,
        doc_id: this.individualData.doc_id,
        tool_name: this.individualData.tool_name || this.autorun?.headers['x-tool-name']
      }
      const {data} = await axios.post(`/tenant-check/output-card`, payload)
      this.fieldsData = data.fields
      if (this.individualData?.bespoke?.length>0) {
        this.fieldsData.forEach((res)=>{
        const existingField = this.individualData.bespoke.find((result)=>result.section_field_id==res.section_field_id)
        res.value = existingField?.value
      })
      }
      data.sections.forEach((res)=>{
        this.sidebarData.push({
          id: res.section_id,
          name: res.section_name
        })
      })
    },
    openEditName() {
      this.editName = !this.editName;
    },
    openMenu(e) {
      if (e && e.stopPropagation) e.stopPropagation();
      if (this.individualData) {
        this.originalData = JSON.parse(JSON.stringify(this.individualData));
      }
      this.showMenu = true;
    },
    closeMenu(e) {
      if (e && e.stopPropagation) e.stopPropagation();
      this.$emit("closeCanvas", this.individualData);
      this.showMenu = false;
      // let graph = { graphClose: false, sourceName: this.sourceName };
      // this.$emit("updateIsShow", graph);
      // let name = {
      //   opencorporates: 1,
      //   dnb: 2,
      //   complyadvantage: 3,
      //   offshoreleaks: 4,
      //   judyrecords: 5,
      //   news: 6,
      // };
      // let page = name[this.sourceName] || 7;
      if (this.isShow) {
        return;
      }
      // if (!areDeeplyEqual(this.originalData, this.individualData)) {
      //   this.$emit("stateClear", page);
      //   this.$toast.error("You haven`t clicked on save button to save Data");
      // }
      // this.selectedPage = 2;
    },
    openModal(data) {
      this.$emit("openPersonModal", data.entity_type.entity, data);
    },
    selectPage(page) {
      this.selectedPage = page.id;
    },
    isActive(item) {
      return this.selectedPage === item.id;
    },
    setIndividualData(data) {
      this.individualData = data;
    },
    async updateEntity() {
      this.loadingBtn = true;
      try {
        this.individualData.bespoke = this.fieldsData
        let payload = { ...this.individualData };
        payload = this.individualData;

        if (payload.bespoke.some((res)=> !res?.value && res.role_mandatory)) {
          this.$toast.error("Please fill all the mandatory fields.")
          this.loadingBtn = false;
          return
        }
        delete payload?.checked;
        delete payload?.categories_type;
        delete payload?.name;
        payload.case_id = this.getNeoCaseId;
        if (payload.status=="" || payload.status == "UNKNOWN") {
          payload.status = 'None'
        }
        let url;
        if (this.mode === "main-entity") {
          url = `/service/main-entity/${this.individualData._id}`;
          await axios.put(url, payload);
        } else if (this.currentCheckName === 'search-engine-tool') {
          if (this.individualData.card_type === 'media') url = `/service/active-passive-media`;
          else url = `/search-engine/${this.individualData._id}`;
          await axios.put(url, payload);
        } else {
          url = `/service/upsert-card-result`;
          payload["case_check_id"] = this.getCheckId;
          payload["query_id"] = this.getQueryId;
          await axios.post(url, payload);
        }
        await this.$emit('updateCardStatus', this.individualData)
        this.$toast.success("Successfully Updated");
        this.closeMenu();
        this.showMenu = false;
        this.loadingBtn = false;
      } catch (err) {
        this.loadingBtn = false;
        console.error(err);
        this.$toast.error(
          err?.response?.data?.detail || this.$t("general.default_error")
        );
      }
    },
    toogleDropdown(e) {
      e.stopPropagation();
      if(!checkPermission('checkOutput.edit_card')) {
        return;
      }
      this.showList = !this.showList;
    },
    closeDropdown() {
      this.showList = false;
    },
    async selectDropdown(event) {
      this.showList = false;
      let key = event.target.innerText;
      if(key === "False Matches") { 
          key = "IRRELEVANT";
      } else if(key === "Unresolved") { 
          key = "UNKNOWN"
      }
      this.status = key.toLowerCase();
      this.individualData.status = this.status.toUpperCase();
    },
  },
  async mounted() {
    // if (this.isOutputShown)
      if (this.status == 'none') {
        this.status = 'unknown'
      }
      await this.getOutputSectionData()
  },
  computed: {
    ...mapGetters(["getNeoCaseId", "getTenantId"]),

    currentCheckName() {
        return this.$route.query?.check_name
    },

    getCheckId() {
      return this.$route.query.check_id;
    },

    getQueryId() {
      return this.queryId ?? this.$route.query.query_id;
    },

    isOutputShown() {
      // IDs from this.sidebarData:
      const exceptions = [1, 2, 3, 4, 5];
      return !exceptions.includes(this.selectedPage);
    },
    
    sidebarItems() {
      const sidebarData = this.sidebarData
        .map((el) => ({
          name: this.$t(`components.saas.canvas_menu.sidebar.${el.key}`),
          ...el, // use element's original name by default
        }))
        .filter(x=> x.show != false);
      if (this.mode === "main-entity") {
        return sidebarData;
      } else if (this.isMainenity) {
        return sidebarData.filter((item) => item.id != 7);
      } else if (this.sourceName == "judyrecords") {
        return sidebarData;
      } else if (this.sourceName == "complyadvantage") {
        return sidebarData;
      } else if (this.sourceName == "opencorporates") {
        return sidebarData;
      } else {
        return sidebarData.filter((item) => item.id != 1);
      }
    },
    confidenceColor() {
      return "";
    },
    getIndividualData() {
      return this.individualData;
    },
  },
  watch: {
    isShow(newVal) {
      if (newVal) {
        if (newVal == true) {
          this.openMenu();
        }
      }
    },
    isEditCanvas(newVal, oldVal) {
      if (newVal || oldVal) {
        this.openMenu();
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "@shared/light-ray/assets/scss/color.scss";

.active_card {
  background-color: rgba(145, 0, 0, 0.08);
}

.button_outline {
  border-radius: 8px;
  border: 1px solid $lightray_product_color;
  background: var(--white, #fff);
  color: $lightray_product_color;
  font-size: smaller;
}

.green-box {
  border-radius: 4px;
  background: var(--light-green, #e1f3d3);
  color: var(--complete, #67b52b);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.label {
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.label-box {
  display: flex;
  width: 25px;
  height: 25px;
  padding: 3px 7px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
}

.high {
  font-size: 12px;
  font-weight: 400;
  color: #67b52b !important;
  background-color: #e1f3d3;
}

.low {
  font-size: 12px;
  font-weight: 400;
  color: #b52b2b !important;
  background-color: #f3d3d3;
}

.medium {
  font-size: 12px;
  font-weight: 400;
  color: #a7b52b !important;
  background-color: #f3f0d3;
}

.unknown {
  border-radius: 30px;
  background: #d7d7d7;
}

.confirmed {
  border-radius: 30px;
  background: #f0f8ea;
}

.potential {
  border-radius: 30px;
  background: rgba(252, 103, 19, 0.11);
}

.irrelevant {
  border-radius: 30px;
  background: #fbeded;
}

.dropdown_parent {
  position: relative !important;
}

.badge {
  display: flex;
  padding: 4px;
  gap: 10px;
  background-color: white;
  width: 25px;
  height: 25px;
  justify-content: center;
  align-items: center;
  border: 0px;
}

.dropdown_content {
  position: absolute !important;
  top: 120% !important;
  right: 0% !important;
  z-index: 9999 !important;
  border-radius: 8px;
  background: var(--white, #fff);
  box-shadow: 2px 2px 11px 0px rgba(68, 82, 90, 0.09);

  li:hover {
    background-color: #d7d7d7 !important;
  }
}
</style>
