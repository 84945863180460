<!-- Localized -->
<template>
  <div>
    <FormulateForm>
      <div class="flex flex-col space-y-1">
      <div
        v-for="field in fieldsDataInput.filter(res=>res.section_id==sectionId &&res.role_read)"
        :key="field.section_field_id"
      >
      <div  v-if="field.type=='Input'" class="h-full rounded-lg mt-1 mb-2">
        <FormulateInput
          @input="setFieldsData"
          class="w-full p-2"
          :label="field.label"
          :validation-messages="{
            matches: 'Retention period should be a whole number.',
          }"
          :placeholder="field.placeholder"
          :validation="field.role_mandatory ? 'required': ''"
          v-model="field.value"
          :disabled="!field.role_write"
          :class="!field.role_write ? 'bg-gray-100 cursor-not-allowed': ''"
          type="text"
          :element-class="
            (context, classes) => ['flex-1 min-w-full'].concat(classes)
          "
        />
      </div>
      <div v-else-if="fieldTypeMapping[field.type]=='datetime-local'"  class="h-full rounded mt-1 mb-2">
        <FormulateInput
           @input="setFieldsData"
          class="w-full p-2"
          :label="field.label"
          :placeholder="field.placeholder"
          :disabled="!field.role_write"
          :class="!field.role_write ? 'bg-gray-100 cursor-not-allowed': ''"
          :validation="field.role_mandatory ? 'required': ''"
          v-model="field.value"
          :type="fieldTypeMapping[field.type]"
          :element-class="
            (context, classes) => ['flex gap-3 min-w-full'].concat(classes)
          "
        />
      </div>
      <div v-else class="h-full rounded mt-1 mb-2">
        <FormulateInput
           @input="setFieldsData"
          :options="field.options"
          :disabled="!field.role_write"
          :class="!field.role_write ? 'bg-gray-100 cursor-not-allowed': ''"
          :validation="field.role_mandatory ? 'required': ''"
          class="w-full p-2"
          :label="field.label"
          :placeholder="field.placeholder"
          v-model="field.value"
          :type="fieldTypeMapping[field.type]"
          variant="alt"
          :element-class="
            (context, classes) => ['flex gap-3 min-w-full'].concat(classes)
          "
        />
      </div>
      </div>
      </div>
    </FormulateForm>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
export default {
  name: "output-section",
  data: () => {
    return {
      fieldTypeMapping: {
        Input: "text",
        "Single select": "select",
        "Multi select": "multi-select",
        "Checkbox": "checkbox",
        "Radio": "radio",
        "Date": "datetime-local" 
      },
      fieldsDataInput: [],
    };
  },
  props: {
    fields: {
      type: Array,
      default: () => {
        return [];
      },
    },
    sectionId: {
        type: String,
        default: () => {return ''}
    },
    
  },
  mounted() {
      this.fieldsDataInput = cloneDeep(this.fields);
  },
  computed: {},
  methods: {
      setFieldsData() {
          this.$emit("setOutputFields", this.fieldsDataInput)
      }
  },
};
</script>


<style lang="scss">
.formulate-input-element {
  input:disabled {
    cursor: not-allowed !important;
  }
}
</style>