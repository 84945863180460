<!-- Localized -->
<template>
  <div>
    <h2>{{ $t('components.saas.attachments.attachments') }}</h2>
    <div class="w-full flex border rounded-md  p-5">
      <div class="flex items-start mr-6 rounded-md" v-if="individualData?.screenshot?.location || response.file">
       <a :href="`${url}${individualData.screenshot.location || response.file}`" v-if="individualData?.screenshot?.file_mime_type ==='application/pdf'" target="_blank"><span class="bg-gray-100 rounded-lg text-blue-600 p-3">{{individualData?.screenshot?.file_name}}</span></a>
       <img v-else class="formulate-input-upload-area rounded-md " :src="`${url}${individualData.screenshot.location || response.file}`">
        <div class="mx-2 cursor-pointer relative -top-2 -left-2" @click.stop="removeAttachment" :title="$t('components.saas.attachments.remove_attachment')">
          <Cross style="fill: #b2b2b2; height: 24px;width: 24px;" />
        </div>
      </div>
      <div class="p-6">
        <!-- <FormulateInput ref="file" type="file" v-model="files" class="w-full rounded-xl group cursor-pointer"
        @input="handleFiles" single :add-label="true" :disabled="disabled" /> -->
        <FormulateInput 
            type="file" 
            name="Tenant logo" 
            :uploader="handleFiles"  
            :value="files"  
            v-model="files"
            @input="handleFiles"
            help="Select a png, pdf, jpg, jpeg or gif to upload." 
            validation="mime:image/jpg,image/png,image/gif,image/jpeg,application/pdf" 
            :validation-messages="{ mime: 'File must be of type jpg/jpeg gif, png'}" 
          />
      </div>
    </div>
    <!-- <select
      class="form-select block px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid rounded-xl transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-red-100 focus:outline-none"
      :class="{ 'border-box-stroke': !error, 'border-red-600': error }" v-model="getValue" style="min-width: 65vw">
      <option :selected="true" :value="null">{{ $t('components.saas.attachments.select_or_add_caption_in_image') }}</option>
      <option v-for="option, idx in getOptions" :key="idx" :value="option.name">
        {{ option.name }}
      </option>
    </select> -->
  </div>
</template>
<script>
import "@shared/plugins/formulate";
// import {uploadAttachment} from "@shared/light-ray/services";
import axios from "@/axios";
import { mapGetters } from "vuex";
import Cross from "@shared/light-ray/assets/svg/cross.svg";

export default {
  name: "Attachments",
  components: {
    Cross,
  },
  props: {
    individualData:Object,
  },
  data() {
    return {
      files: [],
      url:'https://neotas-reports-storage.s3.eu-west-2.amazonaws.com/reports-templates/',
      response:''
    };
  },
  methods: {
    async handleFiles() {
      console.log("handle files")
      let file = this.getOptions()[0].file;
      const formData = new FormData();
      formData.append("file",file);
      let case_id = this.getNeoCaseId;
      const url = '/service/reports/images'
      let response = await axios.post(url, formData, {
        headers: {
          'x-case-id': case_id
        }
      })
      if (!this.individualData.screenshot) this.individualData.screenshot = {};
      this.individualData.screenshot["available"] = true;
      this.individualData.screenshot["location"] =  response.data.file;
      this.individualData.screenshot["file_name"]=file.name
      this.individualData.screenshot["file_mime_type"]=file.type
      this.individualData.screenshot["size"]=file.size
      this.response = response.data.file
    },
    removeAttachment() {
      this.individualData.screenshot = {};
    },
    getPlaceHolder() {
      return this.$t('components.saas.attachments.select_or_add_caption')
    },
    getOptions() {
      return this.files.files
    }
  },
  mounted() {
  },
  computed: {
    ...mapGetters(["getNeoCaseId"]),
    currentCheckName() {
        return this.$route.query?.check_name
    },
  },
};
</script>
<style scoped lang="scss">

</style>